.cfd-main-container {
    /* justify-content: center; */
    align-items: stretch;
    display: flex;
    position: absolute;
    background: transparent;
    top: 130px;
    /* border: 1px solid red; */
    margin-left: 0;
}

.cfd-info-container {
    display: flex;
    padding: 20px 40px 20px 20px;
    border-radius: 10px 0 0 10px;
    font-family: DM Sans;
    width: 38%;
    color: grey;
    flex-direction: column;
    background-color: #eaedde;
    box-sizing: border-box; 
}


.cfd-container {
    display: flex;
    padding: 10px 60px;
    border-radius: 0 10px 10px 0;
    font-family: DM Sans;
    color: grey;
    width: 60%;
    flex-direction: column;
    background-color: #f2f5e4;
    box-sizing: border-box; 
}



.cfd-container p {
    display: flex;
    justify-content: center;
}

.cfd-upload-container {
    display: flex;
    flex-direction: column;
    /* margin: 10px 0px; */
}

.cfd-upload {
    border: 1px solid rgba(0,0,0,0.54);
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: #eaedde;
    padding: 20px;
    height: 220px;
}


.small-font{
    font-size: 35px;
}

.spanconfirm{
color: rgba(0, 0, 0, 0.7);
}

.Draw-upload-container {
    background-color: rgb(202, 197, 197);
    border-radius: 10px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cfd-actions {
    display: flex;
    justify-content: space-between;
    width: 30%;
    height: 100%;
}

.cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cfd-Draw p {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 10px;
}

.cfd-Draw {
    height: 250px;
}

.cfd-upload i {
    margin-bottom: 10px;
}

.cfd-button-group {
    margin-top: 20%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cfd-back-action {
    border-radius: 20px;
    width: 49%;
    height: 48px;
    border-radius: 30px;
    outline: none;
    font-family: DM Sans;
    background-color: transparent;
    border: 1px solid rgb(177, 176, 176);
}

.cfd-next-action {
    border-radius: 20px;
    width: 49%;
    height: 48px;
    border-radius: 30px;
    color: #eaedde;
    outline: none;
    font-family: DM Sans;
    border: none;
    background-color: #464742;
}

.cfd-back-action:hover {
    background-color: #EAEDDE80;
}

.cfd-next-action:hover {
    background-color: black;
}

.cfd-questions {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .cfd-questions::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .screenTwoCover{
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }

  .screenTwoCover::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

.cfd-questions input {
    /* margin: 0% 2%; */
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    border: none;
    border: 1px solid rgb(177, 176, 176);
    outline: none;
    margin-bottom: 10px;
    background: none;
}

.cfd-confirm-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cfd-confirm-container input[type="checkbox"] {
    margin-right: 10px;
    align-self: flex-start;
}

.cfd-label {
    border: 1px solid #B1B0B0;
    padding: 7px;
    border-radius: 10px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cfd-fourth-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0;
}

.cfd-bgImg{
    background-image: url("../../Assets/Images/backgroundImg.png");
    background-size: cover;
    width: 500px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}

.cfd-side-Proj{
    max-height: 500px;
    min-height: 500px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  .cfd-side-Proj p{
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.70);
    font-style: normal;
    font-weight: 400;
  }

  .cfd-side-Proj label{
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.70);
    font-style: normal;
    font-weight: 400;
  }
  
  .cfd-side-Proj::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .live-voice-container{
    border: 1px solid rgba(0,0,0,0.54);
    background-color: #eaedde;
    border-radius: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Data-Privacy-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  
/* Css of the component 0f tree Modal */
.modal-outer-container {
    position: absolute !important;
    background: rgba(199, 204, 178, 0.93);
    width: 100%;
    height: 100%;
    z-index: 2;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: #6C6D66;
    /* border: 3px solid black; */
}

.header-text {
    color: #6C6D66;
    font-weight: 400;
    font-size: 24px;
}

.center-container-box {
    height: 100%;
    /* border: 3px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-div {
    display: block;
    padding: 10px;
    /* border: 3px solid black; */
    width: 60%;
    height: 70%;
    margin: 2rem auto 0 auto;
}

.center-div>div {
    border-radius: 25px;
    background: #EAEDDF;
    padding: 15px;
}


.main-div {
    width: 500px;
    border: 1px solid;
    border-radius: 28px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e9f1ea;
    padding: 20px;
    gap: 30px;
    position: relative;
}

.tree {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.node {
    width: fit-content;
    /* border:2px solid black; */
    padding: 10px;
    cursor: pointer;
    border-radius: 28px;
    background-color: hsl(72, 100%, 75%);
    margin: 30px 0px;
    font-size: xx-small;
    font-family: sans-serif;
    display: flex;
    gap: 4px;
    align-items: center;
    position: relative;
}

.node-box {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    /* border: 2px solid black; */
}

.popup-heading p {
    font-family: sans-serif;
}

.vertical-line {
    position: absolute;
    left: 30%;
    top: 100%;
    border-left: 1px solid;
    height: 180%;
    transform: translateX(-50%);
}

.vertical-line-2 {
    position: absolute;
    left: 170%;
    top: 210%;
    border-left: 1px solid;
    height: 70%;
    transform: translateX(-50%);
}

.horizontal-line {
    position: absolute;
    left: 100%;
    top: 210%;
    border-top: 1px solid;
    width: 140%;
    transform: translateX(-50%)
}

.children {
    padding-left: 30px;
    /* border: 2px solid black; */
}

.childrens-nodes {
    display: flex;
    gap: 75px;
}

.close-button {
    border-radius: 40px;
    border: none;
    background: #000000B2;
    color: #FFFFFFB2;
    height: 42px;
    width: 11%;
    margin-left: auto;
}

.traceModal-grid-joinContributor {
    display: flex;
    flex-direction: column;
    color: #4e4e4e;
    border: 1px;
    border-radius: 40px;
    margin: 0px;
    padding: 16px;
    text-align: center;
    box-shadow: 0px 6px 14px -7px black;
    /* border: 3px solid black; */
    height: 100%
}

#treeWrapper {
    /* border: 3px solid black; */
    width: '70em';
    height: '20em';
}
/* h1 {
    margin: 10% 33%;
  } */
  
  .drawing-area {
    /* width: 600px;
    height: 200px; */
    /* background-color: lightgrey; */
    border: 1px solid red;
    height: 90%;
    /* margin: -8% 23%; */
  }
@import url(https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap);
.cfd-main-container {
    /* justify-content: center; */
    align-items: stretch;
    display: flex;
    position: absolute;
    background: transparent;
    top: 130px;
    /* border: 1px solid red; */
    margin-left: 0;
}

.cfd-info-container {
    display: flex;
    padding: 20px 40px 20px 20px;
    border-radius: 10px 0 0 10px;
    font-family: DM Sans;
    width: 38%;
    color: grey;
    flex-direction: column;
    background-color: #eaedde;
    box-sizing: border-box; 
}


.cfd-container {
    display: flex;
    padding: 10px 60px;
    border-radius: 0 10px 10px 0;
    font-family: DM Sans;
    color: grey;
    width: 60%;
    flex-direction: column;
    background-color: #f2f5e4;
    box-sizing: border-box; 
}



.cfd-container p {
    display: flex;
    justify-content: center;
}

.cfd-upload-container {
    display: flex;
    flex-direction: column;
    /* margin: 10px 0px; */
}

.cfd-upload {
    border: 1px solid rgba(0,0,0,0.54);
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: #eaedde;
    padding: 20px;
    height: 220px;
}


.small-font{
    font-size: 35px;
}

.spanconfirm{
color: rgba(0, 0, 0, 0.7);
}

.Draw-upload-container {
    background-color: rgb(202, 197, 197);
    border-radius: 10px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cfd-actions {
    display: flex;
    justify-content: space-between;
    width: 30%;
    height: 100%;
}

.cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cfd-Draw p {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 10px;
}

.cfd-Draw {
    height: 250px;
}

.cfd-upload i {
    margin-bottom: 10px;
}

.cfd-button-group {
    margin-top: 20%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cfd-back-action {
    border-radius: 20px;
    width: 49%;
    height: 48px;
    border-radius: 30px;
    outline: none;
    font-family: DM Sans;
    background-color: transparent;
    border: 1px solid rgb(177, 176, 176);
}

.cfd-next-action {
    border-radius: 20px;
    width: 49%;
    height: 48px;
    border-radius: 30px;
    color: #eaedde;
    outline: none;
    font-family: DM Sans;
    border: none;
    background-color: #464742;
}

.cfd-back-action:hover {
    background-color: #EAEDDE80;
}

.cfd-next-action:hover {
    background-color: black;
}

.cfd-questions {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .cfd-questions::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .screenTwoCover{
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }

  .screenTwoCover::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

.cfd-questions input {
    /* margin: 0% 2%; */
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    border: none;
    border: 1px solid rgb(177, 176, 176);
    outline: none;
    margin-bottom: 10px;
    background: none;
}

.cfd-confirm-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cfd-confirm-container input[type="checkbox"] {
    margin-right: 10px;
    align-self: flex-start;
}

.cfd-label {
    border: 1px solid #B1B0B0;
    padding: 7px;
    border-radius: 10px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.cfd-fourth-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0;
}

.cfd-bgImg{
    background-image: url(/static/media/backgroundImg.587e57a8.png);
    background-size: cover;
    width: 500px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}

.cfd-side-Proj{
    max-height: 500px;
    min-height: 500px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  .cfd-side-Proj p{
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.70);
    font-style: normal;
    font-weight: 400;
  }

  .cfd-side-Proj label{
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.70);
    font-style: normal;
    font-weight: 400;
  }
  
  .cfd-side-Proj::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .live-voice-container{
    border: 1px solid rgba(0,0,0,0.54);
    background-color: #eaedde;
    border-radius: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Data-Privacy-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  
.inputFieldView {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    outline: none;
    padding: 8px;
    border-radius: 15px;
    text-indent: 10px;
    background: #EAEDDE;
    color: rgba(0, 0, 0, 0.4);
}

.labelText {
    font-family:"DM Sans";
    font-size: medium;
    line-height: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 5px;
}

.cif-copy-icon{
    position: absolute;
    right: 53px;
    top: 40%;
    transform: translateY(-50%);
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.4);
}


/* h1 {
    margin: 10% 33%;
  } */
  
  .drawing-area {
    /* width: 600px;
    height: 200px; */
    /* background-color: lightgrey; */
    border: 1px solid red;
    height: 90%;
    /* margin: -8% 23%; */
  }
/* Styles for the AudioRecorder component */
.audio-recorder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: transparent;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 25px;
    padding: 10px 20px;
  }
  .audio-recorder-controls{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: transparent;
    justify-content: space-around;
    width: 100%;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .audio-record-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgb(228, 94, 94) ;
    background-color: white; 
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  .audio-red-circle{
    width: 45px;
    height: 45px;
    /* background-color: rgba(0, 0, 0, 0.54); */
    background-color: rgb(228, 94, 94);
    border: 2px solid white;
    border-radius: 50%;
  }

  .audio-blue-square{
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.54);
    border: 5px solid #ffffff;
    border-radius: 20%;
  }
  
  .audio-record-button .fa-microphone,
  .audio-record-button .fa-stop{
    color: white;
    font-size: 25px;
  }
 
.fa-play,.fa-pause,.fa-save,.fa-times{
  font-size: 22px;
  cursor: pointer;
}


  
  .audio-recording-indicator {
    position: absolute;
    top: 20px;
    right: -20px;
    border-radius: 50%;
    background-color: #ff4444;
    width: 12px;
    height: 12px;
  }
  
  .audio-time-display {
    /* font-family: 'Arial', sans-serif; */
    font-size: 14px;
    color: #333;
  }
  
  .audio-audio-controls {
    margin-left: 10px;
  }

  
  .audio-audio-controls audio {
    outline: none;
  }
  .audio-device-selector-container {
    position: relative;
  }
  
  .audio-device-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; /* Adjust as needed */
  }
  
  .audio-device-selector {
    position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px; /* Adjust as needed */
  }
  .audio-device-selector option {
    padding: 8px;
  }
  
  .audio-delete-button {
    margin-left: 10px;
    border: none;
    background: none;
    cursor: pointer;
    color: #999;
    transition: color 0.3s ease;
  }
  
  .audio-delete-button .fa-times {
    font-size: 16px;
  }
  
  .audio-delete-button:hover {
    color: #ff4444;
  }
  
  .audio-delete-button:disabled {
    cursor: not-allowed;
    color: #ccc;
  }
.embed-location-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 8px;
    width: 100%;
  }
  
  .embed-allow-location input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 1.5px solid rgba(0, 0, 0, 1);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    /* margin-top: 5px; */
  }
  
  .embed-allow-location input[type="checkbox"]:checked {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .embed-allow-location input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .embed-allow-location {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .embed-allow-label {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    margin-left: 1px;
  }
  
  .embed-info-icon {
    font-size: 8px;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    border-radius: 50%;
    padding: 1px 6px;
    cursor: pointer;
    position: relative;
  }
  
  .embed-slider-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding: 0 25px;
    position: relative;
  }
  
  .embed-slider-track {
    position: relative;
    width: 100%;
    height: 10px;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 5px;
    margin: 20px 0;
  }
  
  .embed-slider-fill {
    position: absolute;
    height: 15px;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 5px 0 0 5px;
    top: -3px;
    left: 0;
  }
  
  .embed-slider-thumb {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.54);
    transform: translateX(-50%);
    cursor: pointer;
    transition: background-color 0.2s ease;
    top: -8px;
  }
  
  .embed-slider-thumb.embed-thumbactive {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .embed-slider-thumb.embed-thumbinactive {
    background-color: rgba(235, 240, 223, 1);
  }
  
  .embed-hidden-slider {
    width: 100%;
    height: 10px;
    opacity: 0;
    position: absolute;
    top: 15px;
    pointer-events: none;
  }
  
  .embed-slider-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    margin-top: 8px;
  }
  
  .embed-location-description{
    display: flex;
    padding: 0 15px;
    margin-top: 15px;
  }
  
  .embed-description-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }
  
  .embed-locationtooltip {
    position: absolute;
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    width: -webkit-max-content;
    width: max-content;
    z-index: 1;
  }
  
  .embed-locationtooltip::after {
    content: "";
    position: absolute;
    bottom: -18px; /* Position the arrow */
    left: 25px; /* Center the arrow */
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  .embed-locationtooltip strong {
    font-weight: bold;
    color: rgba(31, 41, 55, 1);
    display: block;
    font-size: 14px;
  }
  
  .embed-locationtooltip p {
    margin: 5px 0 0;
    color: #666;
    font-size: 12px;
    font-style: italic;
  }
  
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap'); */
.outerMainContainer {
    border: 0px solid black;
    position: absolute;
    right: 0;
    /* top: 10; */
    display: flex;
    justify-content: center;
    background: transparent;
    padding: 10px;
}

/* Target the inner center container design */
.innerCenterContainer {
    padding: 10px 0px;
    border: 0px solid black;
    background: rgba(234, 237, 222, 1);
    border-radius: 10px;
    box-shadow: 1px 1px 10px 0.1px;
}

/* Target the Header View */
.call-for-data-header {
    /* border: 1px solid black; */
    display: flex;
    /* padding: 20px 40px 10px 40px; */
    padding: 3% 8%;
    align-items: center;
    flex-direction: column;
}
.initial-screen-container{
  display: flex;
  /* padding: 20px 40px 10px 40px; */
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.dataType-selection{
    /* padding: 0px 40px; */
}

.headerInputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.headerInputs input {
    width: 90%;
    height: 50px;
    border-radius: 15px;
    font-size: 1.1rem;
    line-height: 20px;
    border: 1px solid #4E4E4EB2;
    background: transparent;
    padding: 24px;
    /* margin-left: 3%; */
    margin-bottom: 20px;
    
    /* Add a subtle box-shadow on focus for better visibility */
    &:focus {
        outline: 0;
        /* Remove default browser outline */
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        /* Example subtle shadow */
    }
    
}
.Custominput{
  border:"1px solid red"
}
.Custominput::placeholder {
  color: gray; /* Customize the placeholder text color */
  font-size: 16px; /* Customize the font size */
  font-style: italic; /* Optional: Add italic style */
}

.headerInputs input::placeholder {
    /* Target the placeholder text */
    font-size: 1.1rem;
    font-family:DM Sans ;
    /* Example desired font size for placeholder */
    color: rgba(0, 0, 0, 0.54);
}

#DataCall::placeholder {
    font-size: 1.9rem;
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.45);
}

#DataCall{
    font-size: 1.9rem;
    padding: 0px;
    /* margin-left: 3%; */
}

.contributionType-container{
    margin-top: 3%;
    border: 1px solid red;
    /* margin-left: 8%; */
}

.cross-container{
  /* text-align: center; */
  margin:5px 15px;
  background: rgba(32, 159, 140, 0.1);
}


.centerMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.DatacallInputFieldstyles{
  color: #000000;
  font-size: 14px;


}

.fieldRowView {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* border: 1px solid black; */
    height: 150px;
    padding: 5px 0px;
    width: 100%;
    margin: 5px 0px;
}

.screenOneFields{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border: 1px solid black; */
  height: 150px;
  padding: 5px 0px;
  width: 100%;
  margin: 5px 0px;
}

.fieldRowView label {
    font-family: 'DM Sans';
    font-size: larger;
    font-weight: 400;
    color: rgba(78, 78, 78, 0.7);

}

/* Target the input tag view in fieldRowView */
.fieldRowView input {
    width: 85%;
    height: 40px;
    line-height: 20px;
    font-size: 1.1rem;
    /* font-family: "Roboto Mono", monospace; */
    color: rgba(0, 0, 0, 0.54);
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 10px;
    background: transparent;
border-radius: 10px;
   
    /* Add a subtle box-shadow on focus for better visibility */
    &:focus {
        outline: 0;
        /* Remove default browser outline */
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        /* Example subtle shadow */
    }
}


.fieldRowView input::placeholder {
    padding: 0;
    /* Target the placeholder text */
    font-size: 1.1rem;
    /* Example desired font size for placeholder */
    color: rgba(0, 0, 0, 0.54);
}

/* Target the dropDown Input View */
.dropDownViewInput {
    height: 55px;
    width: 220px;
    border-radius: 25px;
    border: 2px solid #C7CCB2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropDownViewInput label {
    font-family: 'DM Sans';
    font-size: larger;
    font-weight: 400;
    color: rgba(78, 78, 78, 0.7);
}

.headingScreenTwo {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: flex-start;
    /* border: 1px solid black; */
    width: 100%;
}

.headingScreenTwo p {
    font-family: 'DM Sans';
    font-size: large;
    line-height: 18px;
    font-weight: 400;
    color: rgba(78, 78, 78, 0.7);
}


.basicRow {
    /* border: 1px solid black; */
    width: 100%;
    margin-bottom: 20px;
}
.customSquare {
  display: flex;
  width: 25px;          
  height: 15px;         
  border: 1px solid rgba(0, 0, 0, 0.54); 
  background-color: transparent; 
  margin-right: 10px;
}

.customSquare2{
  display: flex;
  width: 15px;          
  height: 15px;         
  border: 1px solid rgba(0, 0, 0, 0.54); 
  background-color: transparent; 
  margin-right: 10px;
}

.consent-label{
  text-transform: uppercase;
  font-size: 12px;
  color:rgba(78, 78, 78, 0.7);
  display: flex;
  align-items: center;
  /* font-family:Roboto Mono; */
  font-weight: 400;
}

.headingScreenThree {
    margin-bottom: 10px;
    font-family: "DM Mono";
    font-weight: 400;
    font-size: small;
    line-height: 16px;
    color: #4E4E4EB2;
}

.plusButton {
    height: 35px;
    width: 35px;
    border: 1px solid black;
    border-color: rgba(78, 78, 78, 1);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: rgba(78, 78, 78, 1);
}

.flow-selection-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.flow-selection-container p{
  font-size: 14px;
}

.flow-containers{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.plus-icon {
  position: relative;
  width: 15px;
  height: 15px;
}

.plus-icon::before,
.plus-icon::after {
  content: "";
  position: absolute;
  background-color: #48a999;
  width: 100%;
  height: 2px; 
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plus-icon::after {
  transform: translateY(-50%) rotate(90deg);
}

.advanced-plus-icon {
  position: relative;
  width: 15px;
  height: 15px;
}

.advanced-plus-icon::before,
.advanced-plus-icon::after {
  content: "";
  position: absolute;
  background-color: #48a999;
  width: 100%;
  height: 2px; 
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.advanced-plus-icon::after {
  transform: translateY(-50%) rotate(90deg);
}


.standard-container{
 background: linear-gradient(180deg, rgba(212, 243, 183, 0.2) 0%, rgba(35, 160, 141, 0.2) 100%);
border-radius: 15px;
/* height: 50vh; */
width: 49%;
border: 1px solid rgba(0, 0, 0, 0.4);
display: flex;
padding: 25px;
align-items: center;
justify-content: center;
flex-direction: column;
cursor: pointer;
color: rgba(0, 0, 0, 0.54);
transition: background 0.3s ease
}
.standard-container:hover{
  background: linear-gradient(180deg, rgba(212, 243, 183, 0.54) 0%, rgba(35, 160, 141, 0.54) 100%);
}
.standard-container h3{
  font-size: 16px;
}

.standard-container label{
  font-size: 40px;
  color: #48a999;
  cursor: pointer;
  margin: 20px 0px;
  transition: color 0.3s ease; 
}
.standard-container .description {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.standard-container:hover .description {
  display: block;
  opacity: 1;
   margin-top: 10px;
}

.data-call-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.data-call-card {
  /* height: 50vh; */
  cursor: pointer;
  width: 49%;
  background: linear-gradient(180deg, rgba(212, 243, 183, 0.2) 0%, rgba(35, 160, 141, 0.2) 100%);
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 30px 40px;
  /* padding: 25px; */
  text-align: center;
  justify-content: center;
  align-items: center;
  color:rgba(0, 0, 0, 0.54);
  opacity: 1;
  transition: background 0.3s ease-in;
  position: relative;
}

.data-call-card:hover{
  background: linear-gradient(180deg, rgba(212, 243, 183, 0.54) 0%, rgba(35, 160, 141, 0.54) 100%);
   padding: 57px 30px;
}


.data-call-card h3 {
  font-size: 16px;
}



.Advanced-hover-text {
  display: none;
  /* text-align: center; */
  width: 100%;

  /* padding: 20px; */
}

.Advanced-hover-text .plus-icon{
  margin: 5px 0px;
}

.data-call-card:hover .advanced-prompt {
  display: none;
}

.data-call-card:hover .Advanced-hover-text {
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.advanced-prompt {
  margin-top: 20px;
  text-align: left;
  display: block;

}

.advanced-prompt label {
  display: block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 15px;
}

.prompt-input {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;
  height: 30px;
}




/* Target the footer view design */
.footer {
    height: 80px;
    margin-top: auto;
    padding: 5% 8%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footerButton {
    border-radius: 100px;
    width: 45%;
    height: 60px;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    background-color: rgba(0, 0, 0, 0.54);
    text-align: center;
    &:focus {
        outline: 0;
    }
}

.FooterBackbutton{
    border-radius: 100px;
    width: 45%;
    height: 60px;
    color: #0000008a;
    background-color: transparent;
    text-align: center;
    border:1px solid rgba(78, 78, 78, 1);
    &:focus {
        outline: 0;
    }
}

.footerButton:hover {
    background-color: black;
}



.FourthScreencontainer{
    width: 600px;
    height: 450px;
    background: #EAEDDE;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    padding: 40px 20px 0px 20px;
    position: relative;
}

.close-icon {
    position: absolute;
    top: 25px;
    left: 15px;
    cursor: pointer;
}

.FSC-options{
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

.options-popup {
    position: absolute;
    top: 45px;
    left: -10px; /* Align the popup to the left of the image */
    transform: translate(-100%, -50%); 
    z-index: 1;
    background-color:#EAEDDE;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 140px; 
  }
  
  .options-popup ul {
    list-style: none;
    margin: 0;
    color: black;
    padding: 0;
  }
  
  .options-popup li {
    margin: 5px 5px;
    cursor: pointer;
  }
  


.innerFourth{
    border:1px solid rgba(78, 78, 78, 1);
    width: 75%;
    border-radius: 28px;
    display: flex;
    padding: 15px;
    margin-top: 20px;
    justify-content: flex-start;
    flex-direction: column;
    line-height: 10px;
}

.yellowbox{
    background-color: #e5ff80;
    line-height: 1;
    padding: 10px;
    border: none;
    border-radius: 18px;
    font-size: 14px;
    max-width: -webkit-max-content;
    max-width: max-content;
 }

 .projNameBox{
  background-color: rgba(32, 159, 140, 0.43);
    line-height: 1;
    padding: 10px;
    border: none;
    font-family: 'Roobert TRIAL';
    border-radius: 18px;
    font-size: 14px;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin-bottom: 10px;
 }

.headingScreenFour{
    line-height: 12px;
}

.cfd-ProjDescSection{
    display: flex;
    align-items: center;
    width: 40%;
    /* padding-left: 20px; */
    justify-content: flex-start;
}

.cfd-checkbox {
    display: flex;
    align-items: center;
  
  }
  
  .projectdescriptioncheckbox {
    cursor: pointer;
    display: flex;
    font-size: 12px;
    align-items: center;
    position: relative;
  }
  
  .checkmark {
    width: 18px; /* Size of the custom checkbox */
    height: 18px; /* Size of the custom checkbox */
    border: 1px solid rgba(32, 159, 140, 1);
    border-radius: 4px; /* Optional: rounded corners */
    background-color: transparent; /* Default background */
    position: relative; /* Position relative for the tick mark */
    margin: 8px;
  }
  
  .projectdescriptioncheckbox input:checked + .checkmark {
    background-color: rgba(32, 159, 140, 1); /* Background color when checked */
  }
  
  .projectdescriptioncheckbox input:checked + .checkmark::after {
    content: '';
    position: absolute;
    left: 6px; /* Position of the tick mark */
    top: 2px; /* Position of the tick mark */
    width: 5px; /* Width of the tick mark */
    height: 10px; /* Height of the tick mark */
    border: solid white; /* Color of the tick mark */
    border-width: 0 2px 2px 0; /* Create the tick mark shape */
    transform: rotate(45deg); /* Rotate to create the tick mark */
  }
  .contribution-type {
    background-color: transparent;
    border-radius: 8px;
    margin-top: 25px;
    width: 100%;
  }
  
  .contribution-option {
    margin-bottom: 16px;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkbox-container input {
    display: none;
  }
  
  .checkbox-container .Contribution-checkmark {
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid rgba(32, 159, 140, 1);
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
  }
  
  .checkbox-container input:checked + .Contribution-checkmark {
    background-color: #48a999;
    border-color: #48a999;
  }
  
  .checkbox-container input:checked + .Contribution-checkmark:after {
    content: "";
    position: absolute;
    left: 6px; /* Position of the tick mark */
    top: 2px; /* Position of the tick mark */
    width: 5px; /* Width of the tick mark */
    height: 10px; 
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .checkbox-container div {
    display: flex;
    flex-direction: column;
  }
  
  .checkbox-container div strong {
    color: #4a4a4a;
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .checkbox-container div p {
    color: #7a7a7a;
    font-size: 12px;
    margin: 0;
  }
  
  .cfd-selectedvalues{
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #BBBDB3 ;
    border-radius: 50px;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    height: 35px;
    background-color: whitesmoke;
    width: auto;
  }

  .cfd-selectedvalues p{
    margin-top: 15px;
    font-size: 15px;
    color: #4E4E4EB2;
  }
  .step {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 20px;
  }
  
  .step label {
    margin-left: 5px;
  }
  
  .step .activeStep {
    width: 15px;
    height: 15px;
    background-color: #E5FF80;
    border-radius: 50%;
    margin-right: 5px;
    border: 1px solid black;
  }
  
  .step .inactiveStep {
    width: 15px;
    height: 15px;
    background-color: black; /* Color for previous screen */
    border-radius: 50%;
    margin-right: 5px;
    border: 1px solid black;
  }
  
  .step .transparentStep {
    width: 15px;
    height: 15px;
    background-color: transparent; /* Color for next screen */
    border: 1px solid black; /* Add border for visibility */
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .step:not(:last-child) .inactiveStep::after {
    /* content: ""; */
    display: block;
    width: 40px;
    height: 1px;
    background-color: black;
    margin: 0 auto;
  }

  .cfd-line{
     content: "";
     display: block;
     width: 100px;
     height: 1px;
     background-color: rgba(0, 0, 0, 0.54);
     margin: 0 auto;
  }
.custom-dropdown-container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 20px;
}

.input-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.input-container input {
    flex: 1 1;
    padding: 10px;
    border: 0px solid #ccc;
}

.placeholder-icon {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.54);
    width: 20px;
    /* border: 1px solid black; */
}

.options-list {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #EAEDDE !important;
}

.option {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.option:hover {
    background-color: #EAEDDE !important;
}

.selected {
    background-color: #EAFF99;
}

.option-icon {
    margin-left: auto;
}
.custom-dropdown-container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 20px;
}

.input-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.input-container input {
    flex: 1 1;
    padding: 10px;
    border: 0px solid #ccc;
}
.input-container ::placeholder {
    font-size: 16px; /* Adjust the font size as needed */
  }

.placeholder-icon {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.54);
    width: 20px;
    /* border: 1px solid black; */
}

.options-list {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 1000;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #EAEDDE !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.options-list::-webkit-scrollbar {
    display: none;
}

.option {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.option:hover {
    background-color: #EAEDDE !important;
}

.selected {
    background-color: #EAFF99;
}

.option-icon {
    margin-left: auto;
}
.share-dialog {
    width: 400px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background:rgb(234,237,222);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    position: absolute;
    margin-top: 3.5%;
    z-index: 2;
  }
  
  .share-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .share-header h3 {
    /* margin: 0; */
    margin-left: 33%;
    font-size: 16px;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }
  
  .share-body {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    border: 1px solid red;
    width: 100%;
  }
  
  /* .input-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 20px;
  } */
  
  .edit-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
  }
  
  .edit-icon {
    font-size: 16px;
  }
  
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    text-indent: 10px;
    color: rgba(0, 0, 0, 0.4);
    overflow: auto;
    resize: none;
    background: #EAEDDE;
    font-family: DM Sans;
    font-size: 15px;
    max-height: 100px;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .avatars {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    background-color: #ccc;
    border-radius: 50%;
  }
  
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  .actions {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    /* border: 1px solid red; */
    width: 100%;
    justify-content: space-between;
  }
  
  .copy-link-btn,
  .send-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
   
  }
  
  .copy-link-btn {
    background-color:transparent;
    border: 1px solid rgba(0, 0, 0, 0.54);
    min-width: 30%;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    color: rgba(0, 0, 0, 0.54);
  }
  
  .send-btn {
    background-color: rgba(0, 0, 0, 0.54);
    color: white;
    width: 30%;
  }

  .send-btn:hover{
    background-color: rgba(47, 44, 44, 0.54);
  }

  .toggleContainer{
    display: flex;
    width: 80%;
    justify-content: flex-start;
    align-items: center;
  }


.custom-toggle-slider {
    width: 40px;
    height: 22px;
    padding: 2px;
    background-color: transparent; /* Set initial background color to transparent */
    border-radius: 50px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.7); /* Border color */
    cursor: pointer;
    margin: 0 2%;
    transition: background-color 0.2s; /* Smooth transition for background color */
}

.slider::before {
    width: 0;
    height: 0;
}

.slider {
    width: 18px;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s;
}

.custom-toggle-slider.checked {
    background-color: rgba(0, 0, 0, 0.7); /* Set background color when checked */
}

.custom-toggle-slider.checked .slider {
    transform: translate(15px, -50%);
    background-color: white;
}
.share-code{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.share-code label{
    flex-wrap: nowrap;
    display: flex;
    color: rgba(0, 0, 0, 0.54);
}

.input-text-invalid {
  color: rgb(215, 79, 79);
}

.input-text-valid {
  color: rgb(70, 168, 70);
}

  

/* Css of the component 0f tree Modal */
.modal-outer-container {
    position: absolute !important;
    background: rgba(199, 204, 178, 0.93);
    width: 100%;
    height: 100%;
    z-index: 2;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: #6C6D66;
    /* border: 3px solid black; */
}

.header-text {
    color: #6C6D66;
    font-weight: 400;
    font-size: 24px;
}

.center-container-box {
    height: 100%;
    /* border: 3px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-div {
    display: block;
    padding: 10px;
    /* border: 3px solid black; */
    width: 60%;
    height: 70%;
    margin: 2rem auto 0 auto;
}

.center-div>div {
    border-radius: 25px;
    background: #EAEDDF;
    padding: 15px;
}


.main-div {
    width: 500px;
    border: 1px solid;
    border-radius: 28px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e9f1ea;
    padding: 20px;
    grid-gap: 30px;
    gap: 30px;
    position: relative;
}

.tree {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.node {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* border:2px solid black; */
    padding: 10px;
    cursor: pointer;
    border-radius: 28px;
    background-color: hsl(72, 100%, 75%);
    margin: 30px 0px;
    font-size: xx-small;
    font-family: sans-serif;
    display: flex;
    grid-gap: 4px;
    gap: 4px;
    align-items: center;
    position: relative;
}

.node-box {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    /* border: 2px solid black; */
}

.popup-heading p {
    font-family: sans-serif;
}

.vertical-line {
    position: absolute;
    left: 30%;
    top: 100%;
    border-left: 1px solid;
    height: 180%;
    transform: translateX(-50%);
}

.vertical-line-2 {
    position: absolute;
    left: 170%;
    top: 210%;
    border-left: 1px solid;
    height: 70%;
    transform: translateX(-50%);
}

.horizontal-line {
    position: absolute;
    left: 100%;
    top: 210%;
    border-top: 1px solid;
    width: 140%;
    transform: translateX(-50%)
}

.children {
    padding-left: 30px;
    /* border: 2px solid black; */
}

.childrens-nodes {
    display: flex;
    grid-gap: 75px;
    gap: 75px;
}

.close-button {
    border-radius: 40px;
    border: none;
    background: #000000B2;
    color: #FFFFFFB2;
    height: 42px;
    width: 11%;
    margin-left: auto;
}

.traceModal-grid-joinContributor {
    display: flex;
    flex-direction: column;
    color: #4e4e4e;
    border: 1px;
    border-radius: 40px;
    margin: 0px;
    padding: 16px;
    text-align: center;
    box-shadow: 0px 6px 14px -7px black;
    /* border: 3px solid black; */
    height: 100%
}

#treeWrapper {
    /* border: 3px solid black; */
    width: '70em';
    height: '20em';
}
.custom-cfd-main-container {
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: absolute;
    background: transparent;
    /* margin-left: 0; */
    padding-left: 15px;
}

.custom-cfd-info-container {
    display: flex;
    padding: 25px 40px 25px 25px;
    border-radius: 10px 0 0 10px;
    font-family: DM Sans;
    width: 38%;
    color: grey;
    border-top: 1px solid rgba(0, 0, 0, 0.54);
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    border-left: 1px solid rgba(0, 0, 0, 0.54);;
    flex-direction: column;
    background: linear-gradient(180deg, hsla(89, 65%, 85%, 1) 43%, hsla(169, 37%, 60%, 1) 100%);
    box-sizing: border-box; 
    overflow: hidden; 
}

.greenBox{
    background-color: #208b8c;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 30px;
    color: #ddefe9;
    font-size: 14px;
}

.CustomInputFieldstyles{
  color: #000000;
}


.custom-cfd-container {
    display: flex;
    padding: 10px 60px;
    position: relative;
    border-radius: 0 10px 10px 0;
    font-family: DM Sans;
    color: grey;
    width: 60%;
    border-top: 1px solid rgba(0, 0, 0, 0.54);
  border-right: 1px solid rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  border-left: none;
    flex-direction: column;
background: linear-gradient(180deg, hsla(86, 50%, 88%, 1) 41%, hsla(166, 32%, 75%, 1) 100%);;
    box-sizing: border-box; 
    overflow: hidden; 
  }

  /* Responsive adjustments */
@media only screen and (max-width: 768px) {
  .custom-cfd-main-container {
      padding: 10px 0;
      align-items: center;
      width: 100%;
      margin-left: 3%;
  } 
  .custom-cfd-info-container {
      width: 100%;
      height: calc(100vh - 10);
      border-radius: 10px;
      border: none;
  }

  .custom-cfd-container {
    background: linear-gradient(180deg, hsla(89, 65%, 85%, 1) 43%, hsla(169, 37%, 60%, 1) 100%);
    width: 95%;
    height: 100%;
    border-radius: 10px;
    border:  1px solid rgba(0, 0, 0, 0.54);
    padding: 0 0;
}

  .custom-cfd-questions{
    height:95%;
    margin: 0px 0px 10px 0px;
    overflow-y: auto;
    padding: 0;
  }
  .custom-cfd-questions2{
    height: calc(100vh - 880px) ;
  }

  .step label{
    font-size: 16px;
  }
  .SecondaryCardContainer{
    max-height: 100%;
  }

  .custom-SecondaryCardContainer{
    padding: 0 0;
    height: 70vh;
  
  }

  .custom-cfd-bgImg{
    height: 100px;
    width: 150px;
    margin: 10px 0;
  }

  .custom-cfd-confirm-container1{
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .custom-cfd-confirm-container1::-webkit-scrollbar {
    display: none; 
  } 

  .custom-cfd-upload{
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  .custom-cfd-Draw p {
   height: 5%;
  }

  .custom-live-voice-container{
    height: 200px;
  }

  .greenBox{
  
  }
  .custom-cfd-button-group .custom-cfd-next-action{
    background-color: black;
    height: 40px;
    margin-bottom: 10%;
  }
  .custom-cfd-button-group .custom-cfd-back-action{
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 10%;
    height: 40px;
    color: rgba(0, 0, 0, 0.54);
  }
  .custom-cfd-mobile-fourth-card{
   height: 100%;
   padding: 5% 5% 30% 5%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
  }
  .signup-updates{
    display: flex;
    flex-direction: column;
  }
  .signup-updates label{
    margin-bottom: 5%;
    color:  rgba(0, 0, 0, 1);
    font-size: 16px;
  }
  .signup-updates input{
    margin-bottom: 5%;
    width: 85%;
    border-radius: 30px;
    border: none;
    outline: none;
    height: 40px;
    padding: 10px;
    font-size: 18px;
    background-color:rgba(212, 243, 183, 1);
  }
  .signup-updates input::placeholder {
    color: rgba(0, 0, 0, 1); 
    font-size : 18px;
  }
}

.custom-cfd-questions {
  display: flex;
  flex-direction: column;
  margin: 30px 0px 10px 0px;
  padding: 0 50px;
  overflow-y:auto ;
  scrollbar-width: none;
  height: calc(100vh - 280px);
  -ms-overflow-style: none;
}
.custom-cfd-questions::-webkit-scrollbar {
  display: none; 
} 

.custom-cfd-questions2{
  display: flex;
  flex-direction: column;
  margin: 30px 0px 10px 0px;
  padding: 0 50px;
  height: calc(100vh - 400px);
}

.custom-cfd-container p {
    display: flex;
    justify-content: center;
}

.custom-cfd-upload-container {
    display: flex;
    flex-direction: column;
    /* margin: 10px 0px; */
}

.custom-cfd-upload {
    position: relative;
    border: 1px solid #000000;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height:  220px;
    background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2));
    overflow: hidden; 
}

.circle-image1 {
    position: absolute;
    width: 27%; /* Adjust size as needed */
    height: 90%; /* Adjust size as needed */
  }

  .circle-image2 {
    position: absolute;
    width: 29%; /* Adjust size as needed */
    height: 45%; /* Adjust size as needed */
  }
  
  .circle-bottom-left {
    bottom: 0px; /* Adjust position as needed */
    left: -5px; /* Adjust position as needed */
  }
  
  .circle-top-right {
    top: 0px; /* Adjust position as needed */
    right: 0px; /* Adjust position as needed */
  }

.custom-small-font{
    font-size: 35px;
}

.custom-spanconfirm{
color: rgba(0, 0, 0, 0.7);
}

.custom-Draw-upload-container {
    background-color: rgb(202, 197, 197);
    /* background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2)); */
    border-radius: 10px;
    height: 220px;
    /* border: 1px solid #000000; */
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.custom-cfd-Draw p {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
  width: 100%;
}

.custom-cfd-actions {
    display: flex;
    justify-content: space-between;
    width: 30%;
    height: 100%;
}

.custom-cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-cfd-upload i {
    margin-bottom: 10px;
}

.custom-cfd-button-group {
    position: absolute;
    bottom: 0;
    right: 7%;
    display: flex;
    width: 85%;
    flex-wrap: wrap;
    padding: 0 50px;
    margin-bottom: 2%;
    justify-content: space-between;
}

.custom-cfd-back-action {
    border-radius: 20px;
    width: 49%;
    height: 48px;
    border-radius: 30px;
    outline: none;
    font-family: DM Sans;
    background-color: transparent;
    border: 1px solid rgb(177, 176, 176);
}

.custom-cfd-next-action {
    /* border-radius: 20px; */
    width: 49%;
    height: 48px;
    border-radius: 30px;
    color: rgba(255, 255, 255, 0.7);
    outline: none;
    font-family: DM Sans;
    border: none;
    background-color: rgba(32, 159, 140, 0.54);
}

.custom-cfd-back-action:hover {
    background-color: #EAEDDE80;
}

.custom-cfd-next-action:hover {
    background-color: #56B3A3;
}

.custom-cfd-questions input {
    /* margin: 0% 2%; */
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    border: none;
    border: 1px solid rgb(177, 176, 176);
    outline: none;
    margin-bottom: 10px;
    background: none;
}

.custom-cfd-confirm-container1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.custom-cfd-confirm-container1 input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
    margin-right: 10px;
    align-self: flex-start;
    border: 1.5px solid rgba(32, 159, 140, 1);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}

.custom-cfd-confirm-container1 input[type="checkbox"]:checked {
  background-color: rgba(32, 159, 140, 1);
}

.custom-cfd-confirm-container1 input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 6px;
  height: 10px;
  border:1px solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-cfd-label {
    border: 1px solid #B1B0B0;
    padding: 7px;
    border-radius: 10px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-cfd-fourth-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    /* margin: 50px 0; */
    margin-top: 25%;

}

.custom-cfd-bgImg{
    background-size: cover;
    background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2));
    border-radius: 10px;
    display: flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}

.SecondaryCardContainer{
  padding: 0 50px;
  height: calc(100vh - 200px);
}

.custom-SecondaryCardContainer{
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.custom-SecondaryCardContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.ThirdCardContainer{
  padding: 0 30px 0 50px;
  overflow-y: auto; 
  scrollbar-width: none;
  max-height: 60vh;
}
.ThirdCardContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}


.custom-cfd-side-Proj{
    /* max-height: 500px;
    min-height: 500px; */
    height: 60%;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  .custom-cfd-side-Proj p{
    font-family: DM Sans;
    font-style: normal;
    font-weight: 400;
    color: #000000;
    font-weight: 400;
  }

  .custom-cfd-side-Proj label{
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.70);
    font-style: normal;
    font-weight: 400;
  }
  
  .custom-cfd-side-Proj::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .step-label {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  
  .step-label::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 3px;
    background-color: #209f8c;
    border-radius: 3px;
    visibility: hidden;
  }
  
  .step-label.active::after {
    visibility: visible;
  }

  .custom-live-voice-container{
    border: 1px solid rgba(0,0,0,0.54);
    background:linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2));
    border-radius: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Data-Privacy-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
.location-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
}

.allow-location input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 1.5px solid #2a9d8f;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.allow-location input[type="checkbox"]:checked {
  background-color: #2a9d8f;
}

.allow-location input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.allow-location {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.allow-label {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-left: 1px;
}

.info-icon {
  font-size: 8px;
  background-color: #2a9d8f;
  color: white;
  border-radius: 50%;
  padding: 1px 6px;
  cursor: pointer;
  position: relative;
}

.slider-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  padding: 0 25px;
  position: relative;
}

.slider-track {
  position: relative;
  width: 100%;
  height: 10px;
  border: 1px solid #2a9d8f;
  border-radius: 5px;
  margin: 20px 0;
}

.slider-fill {
  position: absolute;
  height: 15px;
  background-color: #2a9d8f;
  border-radius: 5px 0 0 5px;
  top: -3px;
  left: 0;
}

.slider-thumb {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #2a9d8f;
  transform: translateX(-50%);
  cursor: pointer;
  transition: background-color 0.2s ease;
  top: -8px;
}

.slider-thumb.thumbactive {
  background-color: #2a9d8f;
}

.slider-thumb.thumbinactive {
  background-color: rgba(235, 240, 223, 1);
}

.hidden-slider {
  width: 100%;
  height: 10px;
  opacity: 0;
  position: absolute;
  top: 15px;
  pointer-events: none;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-top: 8px;
}

.location-description{
  display: flex;
  padding: 0 15px;
  margin-top: 15px;
}

.description-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.locationtooltip {
  position: absolute;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  width: 30%;
  z-index: 1;
}

.locationtooltip::after {
  content: "";
  position: absolute;
  bottom: -18px; /* Position the arrow */
  left: 25px; /* Center the arrow */
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.locationtooltip strong {
  font-weight: bold;
  color: rgba(31, 41, 55, 1);
  display: block;
  font-size: 14px;
}

.locationtooltip p {
  margin: 5px 0 0;
  color: #666;
  font-size: 12px;
  font-style: italic;
}

.embedMainContainer{
    display: flex;
    flex-direction: column;
}

.embedView{
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, hsla(45, 22%, 93%, 1) 20%, hsla(173, 91%, 80%, 0.2) 100%);
}

.embedHeader{
    display: flex;
    flex-direction: row;
       padding: 1% 3% 2% 3%;
}
.embedGreenBox{
    background-color: #209F8C;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 30px;
    color:  #F1EFE9;
    font-weight: 400;
    font-style: normal;
    font-family: "DM Mono", monospace;
    font-size: 14px;
}

.embedBlackBox{
    background-color: rgba(0, 0, 0, 1);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 30px;
    color: #ddefe9;
    font-size: 14px;
}



.EmbedAboutButton{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: rgba(0, 0, 0, 1);
    background: transparent;
    padding: 5px;
    width: 8%;
    outline: none;
    font-weight: 400;
    border: 1px solid rgba(0, 0, 0, 1);
}

.kindness-container {
    position: relative;
    text-align: center;
    padding: 9% 0% 17% 0%;
    font-family: 'Arial', sans-serif;
    /* overflow: hidden; */
}

.KindnessTitle {
    font-size: 6rem;
    /* font-weight: bold; */
    font-family: Nanum Myeongjo, serif;
    /* font-weight: 200; */
    /* font-style: normal; */
    color: rgba(0, 0, 0, 1); /* Darker text for contrast */
    margin: 0;
}

.subtitle {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 1);
    margin: 50px 0px;
}

.kindness-button {
    display: inline-block;
    padding: 10px 80px;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(32, 159, 140, 0.1); /* Light greenish background */
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

.background-images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
}

.globe {
    position: absolute;
    width: 35%;
    height: auto;
}
.globe1 {
    position: absolute;
    width: 44%;
    height: auto;
}

.top-right {
    bottom: 43%;
    right: 0%;
}

.bottom-left {
    top: 40%;
    left: 0%;
}

.embedContainer{
    padding: 3% 3% 2% 3%;
    display: flex;
    flex-direction: column;
}
.about-project-container {
    padding: 3% 3% 2% 3%;
    font-family: 'Arial', sans-serif;
    color: #333;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.about-heading {
    font-size: 1.5rem;
    font-family: Nanum Myeongjo, serif;
    padding: 3% 3% 0% 3%;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #333;
}

.video-placeholder {
    width: 80%;
    height: 350px;
    background: linear-gradient(180deg, hsla(91, 71%, 84%, 0.2) 10%, hsla(171, 64%, 38%, 0.2) 100%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.play-icon {
    width: 10%; /* Adjust the size of the play icon */
    height: 30%;
}

.about-description {
    font-size: 1rem;
    line-height: 1.6;
    width: 80%;
    margin-bottom: 15px;
}
.about-description p{
    width: 65%;
    color: rgba(0, 0, 0, 1);
}

.read-more {
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-size: 1rem;
}

.read-more:hover {
    text-decoration: underline;
}

.embed-datacall{
    padding: 2% 20% 5% 20%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    background:rgba(250, 245, 229, 1);
}
.custom-embed-cfd-questions {
    display: flex;
    flex-direction: column;
    height: 80vh;
    margin: 30px 0px 10px 0px;
    padding: 0 10% 1% 10%;
    overflow-y:auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .embed-cfd-upload {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.4);
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height:  220px;
    /* background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2)); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.2) 100%);
    overflow: hidden; 
}

.embed-cfd-upload i {
    margin-bottom: 10px;
}

.image-remove-icon {
    position: absolute;
    top: 20px;
    right: 10px;
}



@media only screen and (max-width: 768px) {
    .embed-cfd-upload{
        border: 1px solid rgba(0, 0, 0, 0.4);
      }

      .embedSecondaryCardContainer{
        height: 65vh;
      }
}
@media only screen and (max-width: 1024px) {
    .embed-cfd-upload{
        border: 1px solid rgba(0, 0, 0, 0.4);
      }

      .custom-embed-cfd-questions{
        height:70vh;
        margin: 0px 0px 10px 0px;
        overflow-y: auto;
        padding: 0;
      }

      .embedSecondaryCardContainer{
        height: 70vh;
      }
}


  .custom-embed-cfd-questions::-webkit-scrollbar {
    display: none; 
  } 
  .custom-embed-cfd-questions input {
    /* margin: 0% 2%; */
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    border: none;
    border: 1px solid rgb(177, 176, 176);
    outline: none;
    margin-bottom: 10px;
    background: none;
}

  .embed-cfd-fourth-card{
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
  }
  .embed-lastPage{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
  }

  .embed-live-voice-container{
    border: 1px solid rgba(0,0,0,0.54);
    /* background:linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2)); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.2) 100%);
    border-radius: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .audio-remove-icon {
    position: absolute;
    bottom: 0;
    right: -20px;
}



@media only screen and (max-width: 768px) {
    .embed-datacall{
        padding: 5% 8% 5% 8%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        background:rgba(250, 245, 229, 1);
    }

    .embed-live-voice-container{
        height: 200px;
      }
    
    
}

.embed-step label{
    font-size: 16px;
  }

  .embed-step-label {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  
  .embed-step-label::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 3px;
    visibility: hidden;
  }
  
  .embed-step-label.active::after {
    visibility: visible;
  }

  .embed-cfd-next-action {
    /* border-radius: 20px; */
    width: 49%;
    height: 48px;
    border-radius: 30px;
    color: rgba(255, 255, 255, 0.7);
    outline: none;
    font-family: DM Sans;
    border: none;
    background-color: rgba(0, 0, 0, 0.54);
}

.embed-cfd-next-action:hover {
    background-color: rgba(0, 0, 0, 0.54);
}

.custom-cfd-confirm-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 22px;
    height: 15px;
    border: 1.5px solid rgba(0, 0, 0, 1);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
  }
  
  .custom-cfd-confirm-container input[type="checkbox"]:checked {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .custom-cfd-confirm-container input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }




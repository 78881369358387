.location-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
}

.allow-location input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 1.5px solid #2a9d8f;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.allow-location input[type="checkbox"]:checked {
  background-color: #2a9d8f;
}

.allow-location input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.allow-location {
  display: flex;
  align-items: center;
  gap: 8px;
}

.allow-label {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-left: 1px;
}

.info-icon {
  font-size: 8px;
  background-color: #2a9d8f;
  color: white;
  border-radius: 50%;
  padding: 1px 6px;
  cursor: pointer;
  position: relative;
}

.slider-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  padding: 0 25px;
  position: relative;
}

.slider-track {
  position: relative;
  width: 100%;
  height: 10px;
  border: 1px solid #2a9d8f;
  border-radius: 5px;
  margin: 20px 0;
}

.slider-fill {
  position: absolute;
  height: 15px;
  background-color: #2a9d8f;
  border-radius: 5px 0 0 5px;
  top: -3px;
  left: 0;
}

.slider-thumb {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #2a9d8f;
  transform: translateX(-50%);
  cursor: pointer;
  transition: background-color 0.2s ease;
  top: -8px;
}

.slider-thumb.thumbactive {
  background-color: #2a9d8f;
}

.slider-thumb.thumbinactive {
  background-color: rgba(235, 240, 223, 1);
}

.hidden-slider {
  width: 100%;
  height: 10px;
  opacity: 0;
  position: absolute;
  top: 15px;
  pointer-events: none;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-top: 8px;
}

.location-description{
  display: flex;
  padding: 0 15px;
  margin-top: 15px;
}

.description-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.locationtooltip {
  position: absolute;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  width: 30%;
  z-index: 1;
}

.locationtooltip::after {
  content: "";
  position: absolute;
  bottom: -18px; /* Position the arrow */
  left: 25px; /* Center the arrow */
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.locationtooltip strong {
  font-weight: bold;
  color: rgba(31, 41, 55, 1);
  display: block;
  font-size: 14px;
}

.locationtooltip p {
  margin: 5px 0 0;
  color: #666;
  font-size: 12px;
  font-style: italic;
}

/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap'); */
.outerMainContainer {
    border: 0px solid black;
    position: absolute;
    right: 0;
    /* top: 10; */
    display: flex;
    justify-content: center;
    background: transparent;
    padding: 10px;
}

/* Target the inner center container design */
.innerCenterContainer {
    padding: 10px 0px;
    border: 0px solid black;
    background: rgba(234, 237, 222, 1);
    border-radius: 10px;
    box-shadow: 1px 1px 10px 0.1px;
}

/* Target the Header View */
.call-for-data-header {
    /* border: 1px solid black; */
    display: flex;
    /* padding: 20px 40px 10px 40px; */
    padding: 3% 8%;
    align-items: center;
    flex-direction: column;
}
.initial-screen-container{
  display: flex;
  /* padding: 20px 40px 10px 40px; */
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.dataType-selection{
    /* padding: 0px 40px; */
}

.headerInputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.headerInputs input {
    width: 90%;
    height: 50px;
    border-radius: 15px;
    font-size: 1.1rem;
    line-height: 20px;
    border: 1px solid #4E4E4EB2;
    background: transparent;
    padding: 24px;
    /* margin-left: 3%; */
    margin-bottom: 20px;
    
    /* Add a subtle box-shadow on focus for better visibility */
    &:focus {
        outline: 0;
        /* Remove default browser outline */
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        /* Example subtle shadow */
    }
    
}
.Custominput{
  border:"1px solid red"
}
.Custominput::placeholder {
  color: gray; /* Customize the placeholder text color */
  font-size: 16px; /* Customize the font size */
  font-style: italic; /* Optional: Add italic style */
}

.headerInputs input::placeholder {
    /* Target the placeholder text */
    font-size: 1.1rem;
    font-family:DM Sans ;
    /* Example desired font size for placeholder */
    color: rgba(0, 0, 0, 0.54);
}

#DataCall::placeholder {
    font-size: 1.9rem;
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.45);
}

#DataCall{
    font-size: 1.9rem;
    padding: 0px;
    /* margin-left: 3%; */
}

.contributionType-container{
    margin-top: 3%;
    border: 1px solid red;
    /* margin-left: 8%; */
}

.cross-container{
  /* text-align: center; */
  margin:5px 15px;
  background: rgba(32, 159, 140, 0.1);
}


.centerMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.DatacallInputFieldstyles{
  color: #000000;
  font-size: 14px;


}

.fieldRowView {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* border: 1px solid black; */
    height: 150px;
    padding: 5px 0px;
    width: 100%;
    margin: 5px 0px;
}

.screenOneFields{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border: 1px solid black; */
  height: 150px;
  padding: 5px 0px;
  width: 100%;
  margin: 5px 0px;
}

.fieldRowView label {
    font-family: 'DM Sans';
    font-size: larger;
    font-weight: 400;
    color: rgba(78, 78, 78, 0.7);

}

/* Target the input tag view in fieldRowView */
.fieldRowView input {
    width: 85%;
    height: 40px;
    line-height: 20px;
    font-size: 1.1rem;
    /* font-family: "Roboto Mono", monospace; */
    color: rgba(0, 0, 0, 0.54);
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 10px;
    background: transparent;
border-radius: 10px;
   
    /* Add a subtle box-shadow on focus for better visibility */
    &:focus {
        outline: 0;
        /* Remove default browser outline */
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
        /* Example subtle shadow */
    }
}


.fieldRowView input::placeholder {
    padding: 0;
    /* Target the placeholder text */
    font-size: 1.1rem;
    /* Example desired font size for placeholder */
    color: rgba(0, 0, 0, 0.54);
}

/* Target the dropDown Input View */
.dropDownViewInput {
    height: 55px;
    width: 220px;
    border-radius: 25px;
    border: 2px solid #C7CCB2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropDownViewInput label {
    font-family: 'DM Sans';
    font-size: larger;
    font-weight: 400;
    color: rgba(78, 78, 78, 0.7);
}

.headingScreenTwo {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: flex-start;
    /* border: 1px solid black; */
    width: 100%;
}

.headingScreenTwo p {
    font-family: 'DM Sans';
    font-size: large;
    line-height: 18px;
    font-weight: 400;
    color: rgba(78, 78, 78, 0.7);
}


.basicRow {
    /* border: 1px solid black; */
    width: 100%;
    margin-bottom: 20px;
}
.customSquare {
  display: flex;
  width: 25px;          
  height: 15px;         
  border: 1px solid rgba(0, 0, 0, 0.54); 
  background-color: transparent; 
  margin-right: 10px;
}

.customSquare2{
  display: flex;
  width: 15px;          
  height: 15px;         
  border: 1px solid rgba(0, 0, 0, 0.54); 
  background-color: transparent; 
  margin-right: 10px;
}

.consent-label{
  text-transform: uppercase;
  font-size: 12px;
  color:rgba(78, 78, 78, 0.7);
  display: flex;
  align-items: center;
  /* font-family:Roboto Mono; */
  font-weight: 400;
}

.headingScreenThree {
    margin-bottom: 10px;
    font-family: "DM Mono";
    font-weight: 400;
    font-size: small;
    line-height: 16px;
    color: #4E4E4EB2;
}

.plusButton {
    height: 35px;
    width: 35px;
    border: 1px solid black;
    border-color: rgba(78, 78, 78, 1);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: rgba(78, 78, 78, 1);
}

.flow-selection-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.flow-selection-container p{
  font-size: 14px;
}

.flow-containers{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.plus-icon {
  position: relative;
  width: 15px;
  height: 15px;
}

.plus-icon::before,
.plus-icon::after {
  content: "";
  position: absolute;
  background-color: #48a999;
  width: 100%;
  height: 2px; 
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plus-icon::after {
  transform: translateY(-50%) rotate(90deg);
}

.advanced-plus-icon {
  position: relative;
  width: 15px;
  height: 15px;
}

.advanced-plus-icon::before,
.advanced-plus-icon::after {
  content: "";
  position: absolute;
  background-color: #48a999;
  width: 100%;
  height: 2px; 
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.advanced-plus-icon::after {
  transform: translateY(-50%) rotate(90deg);
}


.standard-container{
 background: linear-gradient(180deg, rgba(212, 243, 183, 0.2) 0%, rgba(35, 160, 141, 0.2) 100%);
border-radius: 15px;
/* height: 50vh; */
width: 49%;
border: 1px solid rgba(0, 0, 0, 0.4);
display: flex;
padding: 25px;
align-items: center;
justify-content: center;
flex-direction: column;
cursor: pointer;
color: rgba(0, 0, 0, 0.54);
transition: background 0.3s ease,
}
.standard-container:hover{
  background: linear-gradient(180deg, rgba(212, 243, 183, 0.54) 0%, rgba(35, 160, 141, 0.54) 100%);
}
.standard-container h3{
  font-size: 16px;
}

.standard-container label{
  font-size: 40px;
  color: #48a999;
  cursor: pointer;
  margin: 20px 0px;
  transition: color 0.3s ease; 
}
.standard-container .description {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.standard-container:hover .description {
  display: block;
  opacity: 1;
   margin-top: 10px;
}

.data-call-container {
  display: flex;
  gap: 10px;
}

.data-call-card {
  /* height: 50vh; */
  cursor: pointer;
  width: 49%;
  background: linear-gradient(180deg, rgba(212, 243, 183, 0.2) 0%, rgba(35, 160, 141, 0.2) 100%);
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 30px 40px;
  /* padding: 25px; */
  text-align: center;
  justify-content: center;
  align-items: center;
  color:rgba(0, 0, 0, 0.54);
  opacity: 1;
  transition: background 0.3s ease-in;
  position: relative;
}

.data-call-card:hover{
  background: linear-gradient(180deg, rgba(212, 243, 183, 0.54) 0%, rgba(35, 160, 141, 0.54) 100%);
   padding: 57px 30px;
}


.data-call-card h3 {
  font-size: 16px;
}



.Advanced-hover-text {
  display: none;
  /* text-align: center; */
  width: 100%;

  /* padding: 20px; */
}

.Advanced-hover-text .plus-icon{
  margin: 5px 0px;
}

.data-call-card:hover .advanced-prompt {
  display: none;
}

.data-call-card:hover .Advanced-hover-text {
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.advanced-prompt {
  margin-top: 20px;
  text-align: left;
  display: block;

}

.advanced-prompt label {
  display: block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 15px;
}

.prompt-input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;
  height: 30px;
}




/* Target the footer view design */
.footer {
    height: 80px;
    margin-top: auto;
    padding: 5% 8%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footerButton {
    border-radius: 100px;
    width: 45%;
    height: 60px;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    background-color: rgba(0, 0, 0, 0.54);
    text-align: center;
    &:focus {
        outline: 0;
    }
}

.FooterBackbutton{
    border-radius: 100px;
    width: 45%;
    height: 60px;
    color: #0000008a;
    background-color: transparent;
    text-align: center;
    border:1px solid rgba(78, 78, 78, 1);
    &:focus {
        outline: 0;
    }
}

.footerButton:hover {
    background-color: black;
}



.FourthScreencontainer{
    width: 600px;
    height: 450px;
    background: #EAEDDE;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    padding: 40px 20px 0px 20px;
    position: relative;
}

.close-icon {
    position: absolute;
    top: 25px;
    left: 15px;
    cursor: pointer;
}

.FSC-options{
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

.options-popup {
    position: absolute;
    top: 45px;
    left: -10px; /* Align the popup to the left of the image */
    transform: translate(-100%, -50%); 
    z-index: 1;
    background-color:#EAEDDE;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 140px; 
  }
  
  .options-popup ul {
    list-style: none;
    margin: 0;
    color: black;
    padding: 0;
  }
  
  .options-popup li {
    margin: 5px 5px;
    cursor: pointer;
  }
  


.innerFourth{
    border:1px solid rgba(78, 78, 78, 1);
    width: 75%;
    border-radius: 28px;
    display: flex;
    padding: 15px;
    margin-top: 20px;
    justify-content: flex-start;
    flex-direction: column;
    line-height: 10px;
}

.yellowbox{
    background-color: #e5ff80;
    line-height: 1;
    padding: 10px;
    border: none;
    border-radius: 18px;
    font-size: 14px;
    max-width: max-content;
 }

 .projNameBox{
  background-color: rgba(32, 159, 140, 0.43);
    line-height: 1;
    padding: 10px;
    border: none;
    font-family: 'Roobert TRIAL';
    border-radius: 18px;
    font-size: 14px;
    max-width: max-content;
    margin-bottom: 10px;
 }

.headingScreenFour{
    line-height: 12px;
}

.cfd-ProjDescSection{
    display: flex;
    align-items: center;
    width: 40%;
    /* padding-left: 20px; */
    justify-content: flex-start;
}

.cfd-checkbox {
    display: flex;
    align-items: center;
  
  }
  
  .projectdescriptioncheckbox {
    cursor: pointer;
    display: flex;
    font-size: 12px;
    align-items: center;
    position: relative;
  }
  
  .checkmark {
    width: 18px; /* Size of the custom checkbox */
    height: 18px; /* Size of the custom checkbox */
    border: 1px solid rgba(32, 159, 140, 1);
    border-radius: 4px; /* Optional: rounded corners */
    background-color: transparent; /* Default background */
    position: relative; /* Position relative for the tick mark */
    margin: 8px;
  }
  
  .projectdescriptioncheckbox input:checked + .checkmark {
    background-color: rgba(32, 159, 140, 1); /* Background color when checked */
  }
  
  .projectdescriptioncheckbox input:checked + .checkmark::after {
    content: '';
    position: absolute;
    left: 6px; /* Position of the tick mark */
    top: 2px; /* Position of the tick mark */
    width: 5px; /* Width of the tick mark */
    height: 10px; /* Height of the tick mark */
    border: solid white; /* Color of the tick mark */
    border-width: 0 2px 2px 0; /* Create the tick mark shape */
    transform: rotate(45deg); /* Rotate to create the tick mark */
  }
  .contribution-type {
    background-color: transparent;
    border-radius: 8px;
    margin-top: 25px;
    width: 100%;
  }
  
  .contribution-option {
    margin-bottom: 16px;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkbox-container input {
    display: none;
  }
  
  .checkbox-container .Contribution-checkmark {
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid rgba(32, 159, 140, 1);
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
  }
  
  .checkbox-container input:checked + .Contribution-checkmark {
    background-color: #48a999;
    border-color: #48a999;
  }
  
  .checkbox-container input:checked + .Contribution-checkmark:after {
    content: "";
    position: absolute;
    left: 6px; /* Position of the tick mark */
    top: 2px; /* Position of the tick mark */
    width: 5px; /* Width of the tick mark */
    height: 10px; 
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .checkbox-container div {
    display: flex;
    flex-direction: column;
  }
  
  .checkbox-container div strong {
    color: #4a4a4a;
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .checkbox-container div p {
    color: #7a7a7a;
    font-size: 12px;
    margin: 0;
  }
  
  .cfd-selectedvalues{
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid #BBBDB3 ;
    border-radius: 50px;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    height: 35px;
    background-color: whitesmoke;
    width: auto;
  }

  .cfd-selectedvalues p{
    margin-top: 15px;
    font-size: 15px;
    color: #4E4E4EB2;
  }
  .step {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 20px;
  }
  
  .step label {
    margin-left: 5px;
  }
  
  .step .activeStep {
    width: 15px;
    height: 15px;
    background-color: #E5FF80;
    border-radius: 50%;
    margin-right: 5px;
    border: 1px solid black;
  }
  
  .step .inactiveStep {
    width: 15px;
    height: 15px;
    background-color: black; /* Color for previous screen */
    border-radius: 50%;
    margin-right: 5px;
    border: 1px solid black;
  }
  
  .step .transparentStep {
    width: 15px;
    height: 15px;
    background-color: transparent; /* Color for next screen */
    border: 1px solid black; /* Add border for visibility */
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .step:not(:last-child) .inactiveStep::after {
    /* content: ""; */
    display: block;
    width: 40px;
    height: 1px;
    background-color: black;
    margin: 0 auto;
  }

  .cfd-line{
     content: "";
     display: block;
     width: 100px;
     height: 1px;
     background-color: rgba(0, 0, 0, 0.54);
     margin: 0 auto;
  }
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');
.embedMainContainer{
    display: flex;
    flex-direction: column;
}

.embedView{
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, hsla(45, 22%, 93%, 1) 20%, hsla(173, 91%, 80%, 0.2) 100%);
}

.embedHeader{
    display: flex;
    flex-direction: row;
       padding: 1% 3% 2% 3%;
}
.embedGreenBox{
    background-color: #209F8C;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 30px;
    color:  #F1EFE9;
    font-weight: 400;
    font-style: normal;
    font-family: "DM Mono", monospace;
    font-size: 14px;
}

.embedBlackBox{
    background-color: rgba(0, 0, 0, 1);
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 30px;
    color: #ddefe9;
    font-size: 14px;
}



.EmbedAboutButton{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: rgba(0, 0, 0, 1);
    background: transparent;
    padding: 5px;
    width: 8%;
    outline: none;
    font-weight: 400;
    border: 1px solid rgba(0, 0, 0, 1);
}

.kindness-container {
    position: relative;
    text-align: center;
    padding: 9% 0% 17% 0%;
    font-family: 'Arial', sans-serif;
    /* overflow: hidden; */
}

.KindnessTitle {
    font-size: 6rem;
    /* font-weight: bold; */
    font-family: Nanum Myeongjo, serif;
    /* font-weight: 200; */
    /* font-style: normal; */
    color: rgba(0, 0, 0, 1); /* Darker text for contrast */
    margin: 0;
}

.subtitle {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 1);
    margin: 50px 0px;
}

.kindness-button {
    display: inline-block;
    padding: 10px 80px;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(32, 159, 140, 0.1); /* Light greenish background */
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

.background-images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
}

.globe {
    position: absolute;
    width: 35%;
    height: auto;
}
.globe1 {
    position: absolute;
    width: 44%;
    height: auto;
}

.top-right {
    bottom: 43%;
    right: 0%;
}

.bottom-left {
    top: 40%;
    left: 0%;
}

.embedContainer{
    padding: 3% 3% 2% 3%;
    display: flex;
    flex-direction: column;
}
.about-project-container {
    padding: 3% 3% 2% 3%;
    font-family: 'Arial', sans-serif;
    color: #333;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.about-heading {
    font-size: 1.5rem;
    font-family: Nanum Myeongjo, serif;
    padding: 3% 3% 0% 3%;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #333;
}

.video-placeholder {
    width: 80%;
    height: 350px;
    background: linear-gradient(180deg, hsla(91, 71%, 84%, 0.2) 10%, hsla(171, 64%, 38%, 0.2) 100%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.play-icon {
    width: 10%; /* Adjust the size of the play icon */
    height: 30%;
}

.about-description {
    font-size: 1rem;
    line-height: 1.6;
    width: 80%;
    margin-bottom: 15px;
}
.about-description p{
    width: 65%;
    color: rgba(0, 0, 0, 1);
}

.read-more {
    color: rgba(0, 0, 0, 1);
    text-decoration: none;
    font-size: 1rem;
}

.read-more:hover {
    text-decoration: underline;
}

.embed-datacall{
    padding: 2% 20% 5% 20%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    background:rgba(250, 245, 229, 1);
}
.custom-embed-cfd-questions {
    display: flex;
    flex-direction: column;
    height: 80vh;
    margin: 30px 0px 10px 0px;
    padding: 0 10% 1% 10%;
    overflow-y:auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .embed-cfd-upload {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.4);
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height:  220px;
    /* background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2)); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.2) 100%);
    overflow: hidden; 
}

.embed-cfd-upload i {
    margin-bottom: 10px;
}

.image-remove-icon {
    position: absolute;
    top: 20px;
    right: 10px;
}



@media only screen and (max-width: 768px) {
    .embed-cfd-upload{
        border: 1px solid rgba(0, 0, 0, 0.4);
      }

      .embedSecondaryCardContainer{
        height: 65vh;
      }
}
@media only screen and (max-width: 1024px) {
    .embed-cfd-upload{
        border: 1px solid rgba(0, 0, 0, 0.4);
      }

      .custom-embed-cfd-questions{
        height:70vh;
        margin: 0px 0px 10px 0px;
        overflow-y: auto;
        padding: 0;
      }

      .embedSecondaryCardContainer{
        height: 70vh;
      }
}


  .custom-embed-cfd-questions::-webkit-scrollbar {
    display: none; 
  } 
  .custom-embed-cfd-questions input {
    /* margin: 0% 2%; */
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    border: none;
    border: 1px solid rgb(177, 176, 176);
    outline: none;
    margin-bottom: 10px;
    background: none;
}

  .embed-cfd-fourth-card{
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
  .embed-lastPage{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .embed-live-voice-container{
    border: 1px solid rgba(0,0,0,0.54);
    /* background:linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2)); */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.2) 100%);
    border-radius: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .audio-remove-icon {
    position: absolute;
    bottom: 0;
    right: -20px;
}



@media only screen and (max-width: 768px) {
    .embed-datacall{
        padding: 5% 8% 5% 8%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        background:rgba(250, 245, 229, 1);
    }

    .embed-live-voice-container{
        height: 200px;
      }
    
    
}

.embed-step label{
    font-size: 16px;
  }

  .embed-step-label {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  
  .embed-step-label::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 3px;
    visibility: hidden;
  }
  
  .embed-step-label.active::after {
    visibility: visible;
  }

  .embed-cfd-next-action {
    /* border-radius: 20px; */
    width: 49%;
    height: 48px;
    border-radius: 30px;
    color: rgba(255, 255, 255, 0.7);
    outline: none;
    font-family: DM Sans;
    border: none;
    background-color: rgba(0, 0, 0, 0.54);
}

.embed-cfd-next-action:hover {
    background-color: rgba(0, 0, 0, 0.54);
}

.custom-cfd-confirm-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 22px;
    height: 15px;
    border: 1.5px solid rgba(0, 0, 0, 1);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
  }
  
  .custom-cfd-confirm-container input[type="checkbox"]:checked {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .custom-cfd-confirm-container input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }



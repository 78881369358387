.inputFieldView {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    outline: none;
    padding: 8px;
    border-radius: 15px;
    text-indent: 10px;
    background: #EAEDDE;
    color: rgba(0, 0, 0, 0.4);
}

.labelText {
    font-family:"DM Sans";
    font-size: medium;
    line-height: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 5px;
}

.cif-copy-icon{
    position: absolute;
    right: 53px;
    top: 40%;
    transform: translateY(-50%);
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.4);
}


.custom-cfd-main-container {
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: absolute;
    background: transparent;
    /* margin-left: 0; */
    padding-left: 15px;
}

.custom-cfd-info-container {
    display: flex;
    padding: 25px 40px 25px 25px;
    border-radius: 10px 0 0 10px;
    font-family: DM Sans;
    width: 38%;
    color: grey;
    border-top: 1px solid rgba(0, 0, 0, 0.54);
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    border-left: 1px solid rgba(0, 0, 0, 0.54);;
    flex-direction: column;
    background: linear-gradient(180deg, hsla(89, 65%, 85%, 1) 43%, hsla(169, 37%, 60%, 1) 100%);
    box-sizing: border-box; 
    overflow: hidden; 
}

.greenBox{
    background-color: #208b8c;
    width: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 30px;
    color: #ddefe9;
    font-size: 14px;
}

.CustomInputFieldstyles{
  color: #000000;
}


.custom-cfd-container {
    display: flex;
    padding: 10px 60px;
    position: relative;
    border-radius: 0 10px 10px 0;
    font-family: DM Sans;
    color: grey;
    width: 60%;
    border-top: 1px solid rgba(0, 0, 0, 0.54);
  border-right: 1px solid rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  border-left: none;
    flex-direction: column;
background: linear-gradient(180deg, hsla(86, 50%, 88%, 1) 41%, hsla(166, 32%, 75%, 1) 100%);;
    box-sizing: border-box; 
    overflow: hidden; 
  }

  /* Responsive adjustments */
@media only screen and (max-width: 768px) {
  .custom-cfd-main-container {
      padding: 10px 0;
      align-items: center;
      width: 100%;
      margin-left: 3%;
  } 
  .custom-cfd-info-container {
      width: 100%;
      height: calc(100vh - 10);
      border-radius: 10px;
      border: none;
  }

  .custom-cfd-container {
    background: linear-gradient(180deg, hsla(89, 65%, 85%, 1) 43%, hsla(169, 37%, 60%, 1) 100%);
    width: 95%;
    height: 100%;
    border-radius: 10px;
    border:  1px solid rgba(0, 0, 0, 0.54);
    padding: 0 0;
}

  .custom-cfd-questions{
    height:95%;
    margin: 0px 0px 10px 0px;
    overflow-y: auto;
    padding: 0;
  }
  .custom-cfd-questions2{
    height: calc(100vh - 880px) ;
  }

  .step label{
    font-size: 16px;
  }
  .SecondaryCardContainer{
    max-height: 100%;
  }

  .custom-SecondaryCardContainer{
    padding: 0 0;
    height: 70vh;
  
  }

  .custom-cfd-bgImg{
    height: 100px;
    width: 150px;
    margin: 10px 0;
  }

  .custom-cfd-confirm-container1{
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .custom-cfd-confirm-container1::-webkit-scrollbar {
    display: none; 
  } 

  .custom-cfd-upload{
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  .custom-cfd-Draw p {
   height: 5%;
  }

  .custom-live-voice-container{
    height: 200px;
  }

  .greenBox{
  
  }
  .custom-cfd-button-group .custom-cfd-next-action{
    background-color: black;
    height: 40px;
    margin-bottom: 10%;
  }
  .custom-cfd-button-group .custom-cfd-back-action{
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 10%;
    height: 40px;
    color: rgba(0, 0, 0, 0.54);
  }
  .custom-cfd-mobile-fourth-card{
   height: 100%;
   padding: 5% 5% 30% 5%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
  }
  .signup-updates{
    display: flex;
    flex-direction: column;
  }
  .signup-updates label{
    margin-bottom: 5%;
    color:  rgba(0, 0, 0, 1);
    font-size: 16px;
  }
  .signup-updates input{
    margin-bottom: 5%;
    width: 85%;
    border-radius: 30px;
    border: none;
    outline: none;
    height: 40px;
    padding: 10px;
    font-size: 18px;
    background-color:rgba(212, 243, 183, 1);
  }
  .signup-updates input::placeholder {
    color: rgba(0, 0, 0, 1); 
    font-size : 18px;
  }
}

.custom-cfd-questions {
  display: flex;
  flex-direction: column;
  margin: 30px 0px 10px 0px;
  padding: 0 50px;
  overflow-y:auto ;
  scrollbar-width: none;
  height: calc(100vh - 280px);
  -ms-overflow-style: none;
}
.custom-cfd-questions::-webkit-scrollbar {
  display: none; 
} 

.custom-cfd-questions2{
  display: flex;
  flex-direction: column;
  margin: 30px 0px 10px 0px;
  padding: 0 50px;
  height: calc(100vh - 400px);
}

.custom-cfd-container p {
    display: flex;
    justify-content: center;
}

.custom-cfd-upload-container {
    display: flex;
    flex-direction: column;
    /* margin: 10px 0px; */
}

.custom-cfd-upload {
    position: relative;
    border: 1px solid #000000;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height:  220px;
    background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2));
    overflow: hidden; 
}

.circle-image1 {
    position: absolute;
    width: 27%; /* Adjust size as needed */
    height: 90%; /* Adjust size as needed */
  }

  .circle-image2 {
    position: absolute;
    width: 29%; /* Adjust size as needed */
    height: 45%; /* Adjust size as needed */
  }
  
  .circle-bottom-left {
    bottom: 0px; /* Adjust position as needed */
    left: -5px; /* Adjust position as needed */
  }
  
  .circle-top-right {
    top: 0px; /* Adjust position as needed */
    right: 0px; /* Adjust position as needed */
  }

.custom-small-font{
    font-size: 35px;
}

.custom-spanconfirm{
color: rgba(0, 0, 0, 0.7);
}

.custom-Draw-upload-container {
    background-color: rgb(202, 197, 197);
    /* background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2)); */
    border-radius: 10px;
    height: 220px;
    /* border: 1px solid #000000; */
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.custom-cfd-Draw p {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;
  width: 100%;
}

.custom-cfd-actions {
    display: flex;
    justify-content: space-between;
    width: 30%;
    height: 100%;
}

.custom-cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-cfd-upload-action {
    contain: content;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-cfd-upload i {
    margin-bottom: 10px;
}

.custom-cfd-button-group {
    position: absolute;
    bottom: 0;
    right: 7%;
    display: flex;
    width: 85%;
    flex-wrap: wrap;
    padding: 0 50px;
    margin-bottom: 2%;
    justify-content: space-between;
}

.custom-cfd-back-action {
    border-radius: 20px;
    width: 49%;
    height: 48px;
    border-radius: 30px;
    outline: none;
    font-family: DM Sans;
    background-color: transparent;
    border: 1px solid rgb(177, 176, 176);
}

.custom-cfd-next-action {
    /* border-radius: 20px; */
    width: 49%;
    height: 48px;
    border-radius: 30px;
    color: rgba(255, 255, 255, 0.7);
    outline: none;
    font-family: DM Sans;
    border: none;
    background-color: rgba(32, 159, 140, 0.54);
}

.custom-cfd-back-action:hover {
    background-color: #EAEDDE80;
}

.custom-cfd-next-action:hover {
    background-color: #56B3A3;
}

.custom-cfd-questions input {
    /* margin: 0% 2%; */
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    border: none;
    border: 1px solid rgb(177, 176, 176);
    outline: none;
    margin-bottom: 10px;
    background: none;
}

.custom-cfd-confirm-container1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.custom-cfd-confirm-container1 input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
    margin-right: 10px;
    align-self: flex-start;
    border: 1.5px solid rgba(32, 159, 140, 1);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}

.custom-cfd-confirm-container1 input[type="checkbox"]:checked {
  background-color: rgba(32, 159, 140, 1);
}

.custom-cfd-confirm-container1 input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 6px;
  height: 10px;
  border:1px solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-cfd-label {
    border: 1px solid #B1B0B0;
    padding: 7px;
    border-radius: 10px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-cfd-fourth-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    /* margin: 50px 0; */
    margin-top: 25%;

}

.custom-cfd-bgImg{
    background-size: cover;
    background: linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2));
    border-radius: 10px;
    display: flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}

.SecondaryCardContainer{
  padding: 0 50px;
  height: calc(100vh - 200px);
}

.custom-SecondaryCardContainer{
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.custom-SecondaryCardContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.ThirdCardContainer{
  padding: 0 30px 0 50px;
  overflow-y: auto; 
  scrollbar-width: none;
  max-height: 60vh;
}
.ThirdCardContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}


.custom-cfd-side-Proj{
    /* max-height: 500px;
    min-height: 500px; */
    height: 60%;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  .custom-cfd-side-Proj p{
    font-family: DM Sans;
    font-style: normal;
    font-weight: 400;
    color: #000000;
    font-weight: 400;
  }

  .custom-cfd-side-Proj label{
    font-family: DM Sans;
    color: rgba(0, 0, 0, 0.70);
    font-style: normal;
    font-weight: 400;
  }
  
  .custom-cfd-side-Proj::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .step-label {
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  
  .step-label::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 3px;
    background-color: #209f8c;
    border-radius: 3px;
    visibility: hidden;
  }
  
  .step-label.active::after {
    visibility: visible;
  }

  .custom-live-voice-container{
    border: 1px solid rgba(0,0,0,0.54);
    background:linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2));
    border-radius: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Data-Privacy-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
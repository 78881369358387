.share-dialog {
    width: 400px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background:rgb(234,237,222);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    position: absolute;
    margin-top: 3.5%;
    z-index: 2;
  }
  
  .share-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .share-header h3 {
    /* margin: 0; */
    margin-left: 33%;
    font-size: 16px;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }
  
  .share-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid red;
    width: 100%;
  }
  
  /* .input-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 20px;
  } */
  
  .edit-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
  }
  
  .edit-icon {
    font-size: 16px;
  }
  
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    text-indent: 10px;
    color: rgba(0, 0, 0, 0.4);
    overflow: auto;
    resize: none;
    background: #EAEDDE;
    font-family: DM Sans;
    font-size: 15px;
    max-height: 100px;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .avatars {
    display: flex;
    gap: 8px;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    background-color: #ccc;
    border-radius: 50%;
  }
  
  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  .actions {
    display: flex;
    gap: 8px;
    /* border: 1px solid red; */
    width: 100%;
    justify-content: space-between;
  }
  
  .copy-link-btn,
  .send-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
   
  }
  
  .copy-link-btn {
    background-color:transparent;
    border: 1px solid rgba(0, 0, 0, 0.54);
    min-width: 30%;
    max-width: fit-content;
    color: rgba(0, 0, 0, 0.54);
  }
  
  .send-btn {
    background-color: rgba(0, 0, 0, 0.54);
    color: white;
    width: 30%;
  }

  .send-btn:hover{
    background-color: rgba(47, 44, 44, 0.54);
  }

  .toggleContainer{
    display: flex;
    width: 80%;
    justify-content: flex-start;
    align-items: center;
  }


.custom-toggle-slider {
    width: 40px;
    height: 22px;
    padding: 2px;
    background-color: transparent; /* Set initial background color to transparent */
    border-radius: 50px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.7); /* Border color */
    cursor: pointer;
    margin: 0 2%;
    transition: background-color 0.2s; /* Smooth transition for background color */
}

.slider::before {
    width: 0;
    height: 0;
}

.slider {
    width: 18px;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s;
}

.custom-toggle-slider.checked {
    background-color: rgba(0, 0, 0, 0.7); /* Set background color when checked */
}

.custom-toggle-slider.checked .slider {
    transform: translate(15px, -50%);
    background-color: white;
}
.share-code{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.share-code label{
    flex-wrap: nowrap;
    display: flex;
    color: rgba(0, 0, 0, 0.54);
}

.input-text-invalid {
  color: rgb(215, 79, 79);
}

.input-text-valid {
  color: rgb(70, 168, 70);
}

  
/* Styles for the AudioRecorder component */
.audio-recorder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: transparent;
    width: 100%;
    gap: 10px;
    border-radius: 25px;
    padding: 10px 20px;
  }
  .audio-recorder-controls{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: transparent;
    justify-content: space-around;
    width: 100%;
    gap: 10px;
  }
  
  .audio-record-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgb(228, 94, 94) ;
    background-color: white; 
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  .audio-red-circle{
    width: 45px;
    height: 45px;
    /* background-color: rgba(0, 0, 0, 0.54); */
    background-color: rgb(228, 94, 94);
    border: 2px solid white;
    border-radius: 50%;
  }

  .audio-blue-square{
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.54);
    border: 5px solid #ffffff;
    border-radius: 20%;
  }
  
  .audio-record-button .fa-microphone,
  .audio-record-button .fa-stop{
    color: white;
    font-size: 25px;
  }
 
.fa-play,.fa-pause,.fa-save,.fa-times{
  font-size: 22px;
  cursor: pointer;
}


  
  .audio-recording-indicator {
    position: absolute;
    top: 20px;
    right: -20px;
    border-radius: 50%;
    background-color: #ff4444;
    width: 12px;
    height: 12px;
  }
  
  .audio-time-display {
    /* font-family: 'Arial', sans-serif; */
    font-size: 14px;
    color: #333;
  }
  
  .audio-audio-controls {
    margin-left: 10px;
  }

  
  .audio-audio-controls audio {
    outline: none;
  }
  .audio-device-selector-container {
    position: relative;
  }
  
  .audio-device-icon {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; /* Adjust as needed */
  }
  
  .audio-device-selector {
    position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px; /* Adjust as needed */
  }
  .audio-device-selector option {
    padding: 8px;
  }
  
  .audio-delete-button {
    margin-left: 10px;
    border: none;
    background: none;
    cursor: pointer;
    color: #999;
    transition: color 0.3s ease;
  }
  
  .audio-delete-button .fa-times {
    font-size: 16px;
  }
  
  .audio-delete-button:hover {
    color: #ff4444;
  }
  
  .audio-delete-button:disabled {
    cursor: not-allowed;
    color: #ccc;
  }
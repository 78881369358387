.custom-dropdown-container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 20px;
}

.input-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.input-container input {
    flex: 1;
    padding: 10px;
    border: 0px solid #ccc;
}
.input-container ::placeholder {
    font-size: 16px; /* Adjust the font size as needed */
  }

.placeholder-icon {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.54);
    width: 20px;
    /* border: 1px solid black; */
}

.options-list {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 1000;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #EAEDDE !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.options-list::-webkit-scrollbar {
    display: none;
}

.option {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.option:hover {
    background-color: #EAEDDE !important;
}

.selected {
    background-color: #EAFF99;
}

.option-icon {
    margin-left: auto;
}
.embed-location-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 8px;
    width: 100%;
  }
  
  .embed-allow-location input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 1.5px solid rgba(0, 0, 0, 1);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    /* margin-top: 5px; */
  }
  
  .embed-allow-location input[type="checkbox"]:checked {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .embed-allow-location input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 4px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .embed-allow-location {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .embed-allow-label {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    margin-left: 1px;
  }
  
  .embed-info-icon {
    font-size: 8px;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    border-radius: 50%;
    padding: 1px 6px;
    cursor: pointer;
    position: relative;
  }
  
  .embed-slider-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding: 0 25px;
    position: relative;
  }
  
  .embed-slider-track {
    position: relative;
    width: 100%;
    height: 10px;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 5px;
    margin: 20px 0;
  }
  
  .embed-slider-fill {
    position: absolute;
    height: 15px;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 5px 0 0 5px;
    top: -3px;
    left: 0;
  }
  
  .embed-slider-thumb {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.54);
    transform: translateX(-50%);
    cursor: pointer;
    transition: background-color 0.2s ease;
    top: -8px;
  }
  
  .embed-slider-thumb.embed-thumbactive {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .embed-slider-thumb.embed-thumbinactive {
    background-color: rgba(235, 240, 223, 1);
  }
  
  .embed-hidden-slider {
    width: 100%;
    height: 10px;
    opacity: 0;
    position: absolute;
    top: 15px;
    pointer-events: none;
  }
  
  .embed-slider-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    margin-top: 8px;
  }
  
  .embed-location-description{
    display: flex;
    padding: 0 15px;
    margin-top: 15px;
  }
  
  .embed-description-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }
  
  .embed-locationtooltip {
    position: absolute;
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    width: max-content;
    z-index: 1;
  }
  
  .embed-locationtooltip::after {
    content: "";
    position: absolute;
    bottom: -18px; /* Position the arrow */
    left: 25px; /* Center the arrow */
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  .embed-locationtooltip strong {
    font-weight: bold;
    color: rgba(31, 41, 55, 1);
    display: block;
    font-size: 14px;
  }
  
  .embed-locationtooltip p {
    margin: 5px 0 0;
    color: #666;
    font-size: 12px;
    font-style: italic;
  }
  